.ed-topbar {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  justify-content: space-between;
}

.trigger {
  margin-right: 20px;
  color: #000;
}

.ed-topbar .title {
  margin-top: 10px;
  margin-left: 15px;
}

.ed-topbar .breadcrumb {
  display: flex;
  align-items: center;
}

.ed-topbar .user {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

