@import "~antd/dist/antd.less";
@menu-image: "menu-bg.jpeg";

@primary-color: #ce0e2d;
@layout-header-background: transparent;

#components-layout-demo-custom-trigger .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

#auth-sider .logo {
  height: 32px;
  margin: 14px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
}

#auth-sider .logo-collapsed {
  height: 48px;
  margin: 6px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #ce0e2d;
}

.site-layout .site-layout-background {
  background: #fff;
  margin: 10px;
}

.ant-layout-sider-light {
  background: #030303;
}

.ant-layout-sider-light::before {
  content: '' !important;
  width: 100% !important;
  height: 100% !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  display: block !important;
  z-index: 0 !important;
  background-image: url(@menu-image) !important;
  background-position: center center !important;
  opacity: 0.1 !important;
  background-size: cover !important;
}

.sidebar-logo {
  display: flex;
  justify-content: center;
  border-top: solid 1px #314e68;
  width: 95%;
  margin: 0px auto;
  position: absolute;
  bottom: 5px;
}

.ant-layout-header {
  padding: 0px 20px !important;
  z-index: 99;
  background-color: #fff;
  border-bottom: solid 1px #eaeaea;
}

.ant-layout-footer {
  padding: 24px 50px;
  color: #b9b9b9;
  font-size: 14px;
  border-top: solid 1px #eaeaea;
  background: #ffffff;
}

.ed-lista-botones {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.ed-lista-botones .ant-btn {
  margin-left: 10px;
}

@media only screen and (max-width: 767px) {
  .ed-lista-botones .ant-btn {
    margin-right: 10px;
    margin-left: 0px;
  }
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: #3b3b3b;
}

.ant-upload.ant-upload-select {
  display: inline-block;
  width: 100%;
  text-align: center;
  cursor: pointer;
}